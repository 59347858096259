export const setPatientId = (patientId: string) => {
    return {
        type: 'SET_PATIENT_ID',
        payload: patientId
    };
}

function delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export const getVitalSignals = (t: any, patientId: string) => {
    return (dispatch: Function) => {

        delay(1000).then(() => {

            //https://restapi-c2c.dxc-technology.cloud/health
            let url = "https://restapi-c2c.dxc-technology.cloud/patient/" + patientId;
            fetch(url, {}).then((resp) => resp.text())
                .then((data) => {
                    // Trick parse JSON twice it removes escaped characters
                    // TODO: FIX THIS ONE DAY
                    let json = JSON.parse(data);
                    console.log("JSON " + JSON.stringify(data));

                    dispatch({
                        type: "SET_VITAL_SIGNALS",
                        payload: json
                    });


                    /*if (json.status_code === 200) {
                        let body = json.body;

                    } */
                }).catch((error) => {

            });
        });

    };
}
