import React from 'react'
// @ts-ignore
import {
    DxcHeader,
    DxcFooter,
    DxcApplicationLayout, DxcAccordion, DxcButton, DxcLink, DxcProgressBar, DxcAlert, DxcHeading
} from "@dxc-technology/halstack-react";
import {translation} from "../translation/translation";
import {useDispatch, useSelector} from "react-redux";
import {Box, Card, CardContent, Grid, Typography} from "@mui/material";
// @ts-ignore
import heart from "../images/heart_attack.png"
// @ts-ignore
import spo2 from "../images/o2.png"
// @ts-ignore
import rr from "../images/lungs.png"
// @ts-ignore
import temp from "../images/thermometer.png"

import {getVitalSignals} from "../actions/patientMonitorActions";


export default function MainPage() {
    const t = translation;
    const dispatch = useDispatch();

    const patientMonitor = useSelector((state: any) => state.patientMonitor);


    dispatch(getVitalSignals(t, patientMonitor.patientId));


    return (
        <>
            <DxcHeader margin="medium" padding="medium" underlined={true}/>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item>
                    <DxcHeading level={3} as="h2" text={patientMonitor.patientId + " " + t("vitalSignals")}/>
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >

                <Box margin={2}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item>
                                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                        Heart Rate
                                    </Typography>
                                </Grid>
                            </Grid>
                            <img style={{width: "128px", height: "128px"}} src={heart} alt="Heart"/>;
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item>
                                    <Typography variant="h5" component="div">
                                        {patientMonitor.heartRate}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>

                <Box margin={2}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item>
                                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                        SPo2
                                    </Typography>
                                </Grid>
                            </Grid>
                            <img style={{width: "128px", height: "128px"}} src={spo2} alt="Heart"/>;
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item>
                                    <Typography variant="h5" component="div">
                                        {patientMonitor.spo2}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>

                <Box margin={2}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item>
                                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                        Body Temperature
                                    </Typography>
                                </Grid>
                            </Grid>
                            <img style={{width: "128px", height: "128px"}} src={temp} alt="Heart"/>;
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item>
                                    <Typography variant="h5" component="div">
                                        {patientMonitor.temp}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>

                <Box margin={2}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item>
                                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                        Respiration Rate
                                    </Typography>
                                </Grid>
                            </Grid>
                            <img style={{width: "128px", height: "128px"}} src={rr} alt="Heart"/>;
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item>
                                    <Typography variant="h5" component="div">
                                        {patientMonitor.rr}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>

            </Grid>

        </>
    );
}

