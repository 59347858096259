import React, {useEffect} from 'react';
import MainPage from './app/MainPage';
import {useDispatch, useSelector} from "react-redux";
import {translation} from "./translation/translation";
import {setPatientId} from "./actions/patientMonitorActions";

function App() {

    const t = translation;
    document.title = t("appName")



    return (
        <>
            <MainPage/>
        </>
    );
}

export default App;
