import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// REDUX BOILERPLATE
import {createStore, applyMiddleware, compose} from 'redux';
// @ts-ignore
import allReducers from './reducers/index'
// @ts-ignore
import {Provider, useDispatch, useSelector} from 'react-redux'

import thunk from 'redux-thunk';
import {setPatientId} from "./actions/patientMonitorActions";

let store: any;

const isDevelopment: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

if (isDevelopment && (window as any).__REDUX_DEVTOOLS_EXTENSION__) {
    store = createStore(allReducers,
        compose (
            applyMiddleware(thunk),
            (window as any).__REDUX_DEVTOOLS_EXTENSION__()
        )
    );
} else {
    store = createStore(allReducers, compose (applyMiddleware(thunk)));
}
// END REDUX

const queryParams = new URLSearchParams(window.location.search)
const patientId = queryParams.get("patientId");

// @ts-ignore
store.dispatch(setPatientId(patientId));

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <App/>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
