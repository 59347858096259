
const initialState = {
    patientId: "",
    vitalSignals: {},
    heartRate: "---",
    spo2: "---",
    temp: "---",
    rr: "---"
}

const patientMonitorReducer = (state: any = initialState, action: any): any => {
    switch (action.type) {
        case 'SET_PATIENT_ID':
            return {
                ...state,
                patientId: action.payload,
            };
        case 'SET_VITAL_SIGNALS': {
            let heartRate = "---";
            let temp = "---";
            let rr = "---";
            let spo2 = "---";
            if (action.payload && action.payload.values) {
                action.payload.values.forEach((value: any) => {
                    switch(value.vitalType) {
                        case "HeartRate":
                            heartRate = value.value + " BPM";
                            break;
                        case "Spo2":
                            spo2 = value.value + " %";
                            break;
                        default:
                        // code block
                    }
                });

            }
            return {
                ...state,
                vitalSignals: action.payload,
                heartRate: heartRate,
                spo2: spo2
            };
        }
        default:
            return state;
    }
}

export default patientMonitorReducer;